import React from "react";
import "../design/IDELandingPage.css";
import LandingPage from "./LandingPage.js";

export default function IDEComponent() {
  return (
    <>
      <div className="ide-container">
        <div className="ide-toolbar">
          <div className="toolbar-btn close-btn"></div>
          <div className="toolbar-btn minimize-btn"></div>
          <div className="toolbar-btn maximize-btn"></div>
        </div>
        <div className="code-editor">
          <div className="code-line">
            <span className="code-import">import</span>{" "}
            <span className="code-variable">'./LandingPage.css';</span>
          </div>
          <div className="code-line">
            <span className="code-import">import</span>{" "}
            <span className="code-variable">Pronouns</span>{" "}
            <span className="code-from">from</span>{" "}
            <span className="code-string">'../../he/him';</span>
          </div>
          <div className="code-line">
            <span className="code-import">import</span>{" "}
            <span className="code-variable">ProfilePicture</span>{" "}
            <span className="code-from">from</span>{" "}
            <span className="code-string">
              '../../image/ProfilePicture.jpg';
            </span>
          </div>
          <div className="code-line">
            <span className="code-struct">const</span>{" "}
            <span className="code-variable">current</span> = (
            <span className="code-variable">job</span>){" "}
            <span>={">"}</span> {"{"}{" "}
            <span className="code-keyword">return</span>{" "}
            <span className="code-string">
              'Looking for Software Engineer / Frontend Developer roles'
            </span>{" "}
            {"};"}
          </div>
          <div className="code-line">
            <span className="code-struct">const</span>{" "}
            <span className="code-variable">location</span> = [{" "}
            <span className="code-string">'Düsseldorf, DE'</span>,{" "}
            <span className="code-string">'On site'</span>,{" "}
            <span className="code-string">'Remote'</span> ];
          </div>
          <div className="code-line">
            <span className="code-comment"></span>
          </div>

          <div className="code-line">
            <span className="code-struct">const</span>{" "}
            <span className="code-ident">Harun</span> = {"() => {"}
          </div>
        </div>
      </div>
      <LandingPage></LandingPage>
      <div className="ide-container">
        <div className="code-editor">
          <div className="code-line">
            <span className="code-line">{"}"}</span>
          </div>
          <div className="code-line">
            <span className="code-struct">export default</span>{" "}
            <span className="code-ident">Harun</span>;
          </div>
        </div>
      </div>
    </>
  );
}
