import React from "react";
import "../design/Credits.css";
import KofiIcon from "../image/kofi-icon.png"

function Credits() {
  return (
    <footer>
      <p className="credit">
        Made with ❤️ by ramenbox <br />
        <a className="credit-text" href="https://ko-fi.com/ramenbox" target="_blank" rel="noopener noreferrer">
          Buy me a coffe <img className="kofi-icon" src={KofiIcon} alt="Ko-fi icon"></img>
        </a>{" "}
      </p>
    </footer>
  );
}

export default Credits;
