import { React, useEffect } from "react";
import "../design/LandingPage.css";
import ProfilePictire from "../image/prop.jpg";
import Linkedin from "../image/linkedin_121509.png";
import Github from "../image/github_1051326.png";
import Mail from "../image/email_1059120.png";


export default function LandingPage() {
  useEffect(() => {
    document.title = "harun.app";
  }, []);

  const handleMailClick = () => {
    window.location.href = 'mailto:harun.kc@posteo.de'; // Replace example@example.com with your email
  };

  const handleLinkedinClick = () => {
    window.open("https://www.linkedin.com/in/harun-karacuban-b47174200/", "_blank");
  };


  const handleGithubClick = () => {
    window.open("https://github.com/ramenbox", "_blank");
  };

  return (
    <div className="background">
      <div className="header">harun[.app]</div>
        <div className="container">
          <p className="nameHeader">Harun Karacuban</p>
          <img src={ProfilePictire} alt="harun sitting on a staircase" className="profilePicture"></img>

          <div className="textDescription">💻 Software Developer</div>
          <div className="textDescription">📍 Köln, Germany</div>
        </div>
        <div className="iconContainer">
          <button className="iconButton" onClick={handleGithubClick}>
            <img src={Github} className="iconImage" alt="github icon"></img>
          </button>
          <button className="iconButton" onClick={handleMailClick}>
            <img src={Mail} className="iconImage" alt="mail icon" />
          </button>
          <button className="iconButton" onClick={handleLinkedinClick}>
            <img src={Linkedin} className="iconImage" alt="linkedin icon"></img>
          </button>
        </div>
    </div>
  );
}
