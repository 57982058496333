import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../design/DestinyRaid.css";
import Logo from "../image/logo_destiny_no_bg.png";
import CodeBlock from "./CodeBlock";
import Credits from "./Credits";

function DestinyRaid() {
  useEffect(() => {
    document.body.classList.add("my-component-body");
    return () => {
      document.body.classList.remove("my-component-body");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>NEZAREC</title>
        <link rel="icon" type="image/png" href="./favicon.ico" sizes="16x16" />
      </Helmet>

      <div className="content">
        <img className="logo" src={Logo} alt="destiny raid logo"></img>
        <div className="heading">NEZAREC</div>
        <div className="text">
          Looking to get real-time information on Destiny game raids? Look no
          further than this Discord bot!{" "}
        </div>

        <a
          className="discord-button"
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.com/api/oauth2/authorize?client_id=1095798790662922330&permissions=414464608256&scope=bot%20applications.commands"
        >
          Add NEZAREC
        </a>

        <div className="text">
          Add this bot to your server to quickly retrieve raid info for any
          player. Designed for Destiny players, it streamlines your experience
          by providing all relevant raid information in seconds. With this tool,
          you can easily plan your raids and ensure your team is prepared for
          any challenge. Perfect for casual and hardcore players looking to
          optimize their gameplay and save time.
        </div>

        <div className="segment-header">Commands</div>
        <CodeBlock text="raid <username#1234> <plattform>"></CodeBlock>
        <div className="segment-text">
          Check player's raid activity: Use this command to see the current raid
          activity of the specified player.
        </div>

        <CodeBlock text="help"></CodeBlock>
        <div className="segment-text">
          If you're looking for guidance or support, try running /help to find
          the information you need.{" "}
        </div>
      </div>
      <Credits />
    </>
  );
}

export default DestinyRaid;
