import "./App.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DestinyRaid from "./components/DestinyRaid";
import IDEComponent from "./components/IdeLandingPage";

function App() {
  return <BrowserRouter>
      <Routes>
        <Route path="/" element={<IDEComponent/>}></Route>
        <Route path="/destinyraid" element={<DestinyRaid />}></Route>
      </Routes>
    </BrowserRouter>;
}

export default App;
