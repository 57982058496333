import React from 'react';
import "../design/CodeBlock.css";

function CodeBlock(props) {
  return (
<div className="terminal">
  <div>
    <span className="terminal-prompt">/</span>
    <span>{props.text}</span>
  </div>
</div>

  );
}

export default CodeBlock;